
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  middleware: ['authenticated'],
  mixins: [Common],
  components: {
    VueQrcode,
  },
  props: {
  },
  data() {
    return {
      show: false,
      qr_code: null,
      pin: null,
    }
  },
  async mounted() {
    let self = this

  },
  computed: {
  },
  methods: {
    async open(){
      let self = this
      self.pin = null
      self.qr_code = null
      self.$store.commit("loading/start")
      await httpClient
        .post('/cli/account/open_door.json')
        .then(async (res) => {
          self.$store.commit("loading/stop")
          if (res.data.status === 'success') {
            console.log(res.data.data)
            self.qr_code = res.data.data.qr_code
            self.pin = res.data.data.pin
            self.show = true
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          self.$store.commit("loading/stop")
        })
    },
  }
}
