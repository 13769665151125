
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import { training_event_status } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import VideoInputs from '~/components/util/videos/VideoInputs'
import IconAvatar from '~/components/util/IconAvatar'
import LessonEventSetHistory from '~/components/common/lesson/EventSetHistory'
import Rating from '~/components/util/Rating'

export default {
  mixins: [Common],
  components: {
    IconAvatar,
    DocumentInputs,
    VideoInputs,
    LessonEventSetHistory,
    Rating,
  },
  props: {
  },
  data() {
    return {
      //- event
      showTrainingEventModal: false,
      edit_training_event: null,
      client_training_event: null,
      client_training_sets: [],

      //- lesson
      showLessonModal: false,
      edit_lesson: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    getDocs(){
      let docs = []
      this.edit_training_event.media.doc_ids.forEach(doc_id => {
        let doc = _.find(this.edit_training_event.docs, ["id", doc_id])
        if(doc) docs.push(doc)
      })
      return docs
    },
    getVideos(){
      return _.filter(this.edit_training_event.videos, (d) => {return this.edit_training_event.media.video_ids.includes(d.id)})    
    },
    sortedClientVideos(){
      return _.sortBy(this.edit_training_event.client_videos, "created_at").reverse()
    },
    filterdTargetMachines(){
      return _.filter(this.training_machines, (tm) => {return this.edit_training_event.machine.includes(tm.id)})
    },
    filterdTargetMuscles(){
      return _.filter(this.training_muscles, (tm) => {return this.edit_training_event.target_muscles.includes(tm.id)})
    },
    dateSortReverse(){
      return _.sortBy(this.client_training_sets, "date").reverse()
    },
    training_status_text(){
      if(!this.client_training_event) return
      return _.find(training_event_status, ["value", this.client_training_event.status]).text
    },
  },
  methods: {
    async open(training_event){
      if(!(this.company.use_training_log && this.headquarter.show_training_event_to_client)) return
      if(training_event) this.edit_training_event = training_event
      await this.getTrainingEvent()
      this.showTrainingEventModal = true
    },

    async getTrainingEvent(){
      let self = this
      await httpClient
      .get(`/cli/floor/training_events/${this.edit_training_event.uid}.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.edit_training_event = res.data.data.training_event
          self.client_training_event = res.data.data.client_training_event
          self.client_training_sets = res.data.data.client_training_sets
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    },

    // Lesson
    async showLessonDetail(lesson){
      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
      .get(`/cli/floor/lessons/${lesson.uid}.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.edit_lesson = res.data.data.lesson
          setTimeout(()=>{
            self.showLessonModal = true
          }, 100)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
