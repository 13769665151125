export default {
  data() {
    return {}
  },
  methods: {
    inbodyDecoded(url){
      // let url = "https://qrcode.inbody.com?IBData=570-32PG600A6AJ!147!!!!!!!!!!!18300240M20230713173106027402560314017601580193011901110135042303830469021000890177045004140506057605320650061105640689082106260848111403380960018990245025603370962093303300940091202660949092210221049101810231050101903900690737-0084-0099+00151300000130195400130199401100236000310163900310161916900092008000900980111039303680450032002850348338734750261245223882975307002212205217925832667016819691958000000000PASS0!!!!!!!!!!!!!!101802046028501750123004260111046006260352073705910000015000850081000000000001120691110570DM-0422!!!!!!0220003510035102800009750097509750317038709190316038600810185025003000100020000"
      console.log("inbodyDecoded", url)
      // let data_str = url.replace("https://qrcode.inbody.com/?IBData=","")
      try{
        let data_str = url.split("=")[1]
        let code_type = data_str.substr(0, 6)
        console.log("code_type", code_type, data_str)
        if(code_type === "270-12"){
          this.edit_lesson.weight = parseInt(data_str.substr(118, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(150, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(176, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(146, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(355, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(90, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(102, 4)) / 10
          // 部位別筋肉量

        }
        if(code_type === "270-22"){
          window.storeCtl.commit("alert/setError", "対応していない機種です")
        }
        if(code_type === "270S-5"){
          this.edit_lesson.weight = parseInt(data_str.substr(125, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(158, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(184, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(154, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(323, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(97, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(109, 4)) / 10    
        }
        if(code_type === "270-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(119, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(152, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(178, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(148, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(357, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(91, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(103, 4)) / 10    
        }
        if(code_type === "470-12"){
          window.storeCtl.commit("alert/setError", "対応していない機種です")
        }
        if(code_type === "470-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(139, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(172, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(244, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(168, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(115, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(91, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(103, 4)) / 10
        }
        if(code_type === "570-12"){
          this.edit_lesson.weight = parseInt(data_str.substr(148, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(180, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(315, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(176, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(124, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(100, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(112, 4)) / 10
        }
        if(code_type === "570-32"){
          this.edit_lesson.weight = parseInt(data_str.substr(149, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(182, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(322, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(178, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(125, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(101, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(113, 4)) / 10
        }
        if(code_type === "380-62"){
          this.edit_lesson.weight = parseInt(data_str.substr(147, 4)) / 10
          this.edit_lesson.fat_rate = parseInt(data_str.substr(208, 4)) / 10
          this.edit_lesson.basal_metabolism = parseInt(data_str.substr(429, 4))
          this.edit_lesson.bmi = parseInt(data_str.substr(184, 4)) / 10
          this.edit_lesson.muscle_weight = parseInt(data_str.substr(171, 4)) / 10
          this.edit_lesson.fat_weight = parseInt(data_str.substr(123, 4)) / 10
          this.edit_lesson.water_weight = parseInt(data_str.substr(135, 4)) / 10
        }
      }catch(e){
        window.storeCtl.commit("alert/setError", "データのスキャンに失敗しました")
      }
    },
  }
}
