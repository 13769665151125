
import _ from 'lodash';
import Common from '~/mixins/common'
import GButton from '~/components/util/GButton'
import TrainingEventDetail from '~/components/common/training_event/Detail'
import Rating from '~/components/util/Rating'

export default {
  mixins: [Common],
  components: {
    GButton,
    TrainingEventDetail,
    Rating,
  },
  props: {
    event_set: Object,
  },
  data() {
    return {
      numbers: [
        "①","②","③","④","⑤","⑥","⑦","⑧","⑨","⑩",
        "⑪","⑫","⑬","⑭","⑮","⑯","⑰","⑱","⑲","⑳"
      ],
    }
  },
  async mounted() {
  },
  filters: {
  },
  computed: {
    training_category(){
      return _.find(this.training_categories, ["id", this.training_event.training_category_id])
    },
    training_event(){
      let training_event = _.find(this.training_events, ["uid", this.event_set.event.uid])
      if(training_event){
        training_event.is_able = true
        return training_event
      }else{
        // MEMO:種目削除した場合はこっちになる
        this.event_set.event.is_able = false
        return this.event_set.event
      }
    },
  },
  watch: {
    event_sets(obj){
    },
  },
  methods: {
    indexNumber(index){
      if(index < 21){
        return this.numbers[index]
      }else{
        return `${index + 1}. `
      }
    }
  },
}
